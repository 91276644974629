// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    apiBaseUrl: 'https://bulk.malady.co/v1',
    redirectSignIn: 'http://localhost:4200/auth/login',
    redirectSignOut: 'http://localhost:4200/login',
    AWS_ACCESS_KEY_ID:'AKIAQSBSK4O5RNOPOYEA',
    AWS_SECRET_ACCESS_KEY:'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',
    AWS_REGION:'us-east-2',
    AWS_ACL:'public-read',
    AWS_BUCKET_NAME:'maladytest'
};

export const stripe = {
    stripe_key: 'pk_test_51K2eU9DZKxMYHpotQwMVgBrPOstPFG3GxG7Elr3MzQg4P2fh5oIuekExGwqweS8Lx8SjIntCSfsc5Gc1HMQqZg9g00D4QmJBoL'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
